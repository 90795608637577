let App = function() {
  this.initialize();
};

App.prototype.initialize = function() {
  this.menuToggle = document.querySelectorAll('.js-menu-toggle')[0];
  this.menuMobile = document.querySelectorAll('.js-menu-mobile')[0];
  this.newsletterForm = document.querySelectorAll('.js-newsletter-form')[0];
  this.userEmailField = document.querySelectorAll('.js-user-email')[0];
  this.feedbackField = document.querySelectorAll('.js-newsletter-form-feedback')[0];

  this.canvasImage = new CanvasImage();
  this.addEventListeners();
};

App.prototype.addEventListeners = function() {
  if(this.menuToggle) this.menuToggle.addEventListener('click', (e) => {
    if(e.currentTarget.classList.contains('is-active')){
      e.currentTarget.classList.remove('is-active');
      this.menuMobile.classList.remove('is-active');
    } else {
      e.currentTarget.classList.add('is-active');
      this.menuMobile.classList.add('is-active');
    }
  });

  if(this.newsletterForm) {
    this.newsletterForm.addEventListener('submit', function(e) {
      e.preventDefault();

      this.feedbackField.classList.remove('is-success');
      this.feedbackField.classList.remove('is-error');

      if(!this.userEmailField.value){
        this.showFormFeeback('is-error', 'Het veld voor uw e-mail adres mag niet leeg zijn');
      } else {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', window.location.origin + '/wp-content/themes/avanti/includes/subscribe.php?user_email=' + encodeURIComponent(this.userEmailField.value));
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.addEventListener('load', this.handleXHResponse.bind(this));
        xhr.send();
      }
    }.bind(this));
  }
};

App.prototype.handleXHResponse = function(e) {
  var response = JSON.parse(e.currentTarget.response);
  var className, feebackText;
  
  if(!response.error){
    className = 'is-success';
    feebackText = 'Bedankt, uw e-mail adres is opgeslagen';
  } else {
    className = 'is-error';
    switch(response.error.code){
    case 201:
      feebackText = 'Het veld voor uw e-mail adres mag niet leeg zijn';
      break;
    case 204:
      feebackText = 'Uw e-mail adres was al opgeslagen';
      break;
    case 208:
      feebackText = 'Het opgegeven e-mail adres is ongeldig';
      break;
    default:
      feebackText = response.error.message;
      break;
    }
  }
  this.showFormFeeback(className, feebackText);
};

App.prototype.showFormFeeback = function(className, msg) {
  this.feedbackField.classList.add(className);
  this.feedbackField.innerHTML = msg;
};

let CanvasImage = function() {
  this.initialize();
};

CanvasImage.prototype = Object.assign(CanvasImage.prototype, {

  ui: {
    images: document.querySelectorAll('.js-canvas-image')
  },

  colors: {},

  initialize: function() {
    this._setupColors();
    this._setup(this.ui.images);
  },

  _setupColors: function() {
    this.colors['white'] = '#fff';
    this.colors['grey'] = '#aeaeae';
    this.colors['grey-light'] = '#e6e7e8';
    this.colors['grey-medium'] = '#d3d5d7';
    this.colors['grey'] = '#aeaeae';
    this.colors['black'] = '#000';
    this.colors['red-light'] = '#ff8c8c';
    this.colors['red'] = '#ff6565';
    this.colors['purple-dark'] = '#cc8899';
    this.colors['purple'] = '#cc8899';
    this.colors['orange'] = '#ffa153';
    this.colors['ocre-light'] = '#cdb85d';
    this.colors['ocre'] = '#c4983a';
    this.colors['sand'] = '#d6b087';
    this.colors['blue-light'] = '#8cb4ff';
    this.colors['blue'] = '#5280da';
    this.colors['blue-dark'] = '#7abfdf';
    this.colors['blue-sea'] = '#89b5af';
  },

  _setup: function(images) {
    if(!images || !images.length) return;

    this.images = [];
    var canvasImage;
    for(var i = 0, leni = images.length; i < leni; i++){
      canvasImage = {
        img: new Image(),
        ctx : images[i].getContext('2d'),
        width: images[i].width,
        height: images[i].height,
        color: this.colors[images[i].dataset.color] || this.colors['grey']
      };
      canvasImage.img.onload = this._renderImage.bind(this, canvasImage);
      canvasImage.img.src = images[i].dataset.src;
      this.images.push(canvasImage);
    }
  },

  _renderImage: function(canvasImage) {
    canvasImage.ctx.drawImage(canvasImage.img, 0, 0, canvasImage.width, canvasImage.height);
    canvasImage.ctx.globalCompositeOperation = 'multiply';
    canvasImage.ctx.fillStyle=canvasImage.color;
    canvasImage.ctx.fillRect(0,0,canvasImage.width, canvasImage.height);
  }
});


window.onload = function() {
  new App();
};